
import Vue from 'vue'
import { pagingBuilder, sortingBuilder } from 'kwik-vue/utils/tables'
import { ActiveFilterItem, IHeaderOption, IPagingInput, ISortingInput, KwikMediumContainer, KwikPageToolbar, KwikTooltipButtonIcon } from 'kwik-vue'
import { IPriceListGetInput } from '../types'
import { dispatchGetPriceListsCurrentPage } from '../store/actions'
import { readPriceListsCount, readPriceListsCurrentPage } from '../store/getters'
import { FilterOption } from '@/components/filtersBar/FilterOptions'
import { FilterTypes } from '@/components/filtersBar/FilterTypes'
import FiltersBar from '@/components/filtersBar/FiltersBar.vue'

export default Vue.extend({
  name: 'FFPriceLists',
  components: { KwikMediumContainer, KwikPageToolbar, KwikTooltipButtonIcon, FiltersBar },
  watch: {
    options: {
      async handler () {
        await this.loadPage()
      },
      deep: true
    },
    filters: {
      async handler () {
        await this.loadPage()
      },
      deep: true
    }
  },
  data () {
    return {
      options: {} as any,
      loading: true,
      filters: [] as ActiveFilterItem[],
      showFilters: true,
      breadcrumbs: [
        { text: 'Food Factory', disabled: true },
        { text: 'Listini', disabled: false }
      ]
    }
  },
  computed: {
    headers () : IHeaderOption[] {
      const defaultHeaders = [
        {
          text: 'Fornitore',
          sortable: true,
          value: 'business_name',
          hidden: false,
          align: 'start'
        },
        {
          text: 'Materiale',
          sortable: true,
          value: 'material_name',
          hidden: false,
          align: 'start'
        },
        {
          text: 'UM',
          sortable: true,
          value: 'material_unit_of_measure',
          hidden: false,
          align: 'start'
        },
        {
          text: 'Costo',
          sortable: true,
          value: 'unit_cost',
          hidden: false,
          align: 'start'
        },
        {
          text: 'Costo Lunga Scadenza',
          sortable: true,
          value: 'unit_cost_long_term',
          hidden: false,
          align: 'start'
        },
        {
          text: 'Scadenza media (GG)',
          sortable: true,
          value: 'base_expiration_days',
          hidden: false,
          align: 'start'
        },
        {
          text: 'Scadenza media lungo termine (GG)',
          sortable: true,
          value: 'long_term_expiration_days',
          hidden: false,
          align: 'start'
        },
        {
          text: 'Quantità ordinabili',
          sortable: true,
          value: 'quantity',
          hidden: false,
          align: 'start'
        },
        {
          text: 'Dimensione Lotto',
          sortable: true,
          value: 'lot_size',
          hidden: false,
          align: 'start'
        }
      ] as IHeaderOption[]
      return defaultHeaders
    },
    priceListsItems () {
      return readPriceListsCurrentPage(this.$store)
    },
    total () {
      return readPriceListsCount(this.$store)
    },
    filterOptions (): FilterOption[] {
      return [
        {
          key: 'supplier_id',
          label: 'Fornitore',
          field: 'supplier_id',
          type: FilterTypes.SUPPLIER
        },
        {
          key: 'material_id',
          label: 'Materia Prima',
          field: 'material_id',
          type: FilterTypes.RAW_MATERIAL
        }
      ] as FilterOption[]
    }
  },
  methods: {
    async loadPage () {
      this.loading = true
      const paging: IPagingInput = pagingBuilder(this.options)
      const sorting: ISortingInput = sortingBuilder(this.options)
      let supplierId = null
      let materialId = null
      if (this.filters) {
        this.filters.forEach((filter) => {
          switch (filter.field) {
            case 'supplier_id':
              supplierId = filter.value
              break
            case 'material_id':
              materialId = filter.value
              break
            default:
            // nothing
          }
        })
      }
      const payload: IPriceListGetInput = {
        paging: paging,
        sorting: sorting,
        supplierId: supplierId,
        materialId: materialId
      }
      await dispatchGetPriceListsCurrentPage(this.$store, payload)
      this.loading = false
    },
    toggleFilter () {
      this.showFilters = !this.showFilters
    },
    filterData (activeFilters: ActiveFilterItem[]) {
      this.filters = activeFilters
    }
  },
  mounted () {
    this.loadPage()
  }
})
